import './App.css';

function App() {
  return (
    <div className="App">
      <div className="title-box"><img className="logo" src="logo.png" /><h1 className="title">Nate's Games</h1></div>
      <h2 className="subtitle">Under Construction</h2>
    </div>
  );
}

export default App;
